import React from 'react';
import '../Additionalcss/Service.css'
import Header from '../Components/Header'
import app from '../image/app.png'
import market from '../image/market.png'
import website from '../image/website.png'
import inteligent from '../image/inteligent.jpg'
import code from '../image/code.jpg'
import reportingimg from '../image/reportingimg.jpg'
import bi from '../image/bi.png'
import webui from '../image/webui.jpg'
import approval from '../image/approval.jpg'
import database from '../image/database.jpg'
import Report from '../image/Report.png'
import iosicon from '../image/iosicon.png'
import mobflow from '../image/mobflow.png'
import Arrow from '../Components/Arrow'
import Footer from '../Components/Footer'
import Picture1 from '../image/Picture1.png'
import Picture2 from '../image/Picture2.png'
import Picture3 from '../image/Picture3.png'
import Picture4 from '../image/Picture4.png'
import Picture5 from '../image/Picture5.png'
import Picture6 from '../image/Picture6.png'
import Picture7 from '../image/Picture7.png'
import Picture8 from '../image/Picture8.png'
import Picture9 from '../image/Picture9.png'
import Picture10 from '../image/Picture10.png'
import Picture11 from '../image/Picture11.png'
import Picture12 from '../image/Picture12.png'
import Picture13 from '../image/Picture13.png'
import Picture14 from '../image/Picture14.png'
import Picture15 from '../image/Picture15.png'
import Picture16 from '../image/Picture16.png'
import sqlite from '../image/sqlite.png'
import Picture17 from '../image/Picture17.png'
import Picture18 from '../image/Picture18.png'
import Picture19 from '../image/Picture19.png'
import Picture20 from '../image/Picture20.png'
import Picture21 from '../image/Picture21.png'
import aws from '../image/aws.png'
import azure from '../image/azure.png'
import amazon from '../image/amazon.png'
import gcloud from '../image/gcloud.png'
import oracle from '../image/oracle.png'
import informatica from '../image/informatica.png'
import IBM from '../image/IBM.png'

import Picture22 from '../image/Picture22.png'
import Picture23 from '../image/Picture23.png'
import Picture24 from '../image/Picture24.png'
import Picture25 from '../image/Picture25.png'
import Picture26 from '../image/Picture26.png'
import Picture27 from '../image/Picture27.png'
import Picture28 from '../image/Picture28.png'
import Picture29 from '../image/Picture29.png'
import Picture31 from '../image/Picture31.png'
import Picture32 from '../image/Picture32.png'
import Picture33 from '../image/Picture33.png'
import Picture34 from '../image/Picture34.png'
import Picture35 from '../image/Picture35.png'
import Picture36 from '../image/Picture36.png'

 function Services() {
    
    return (
        <div>
            <Header/>
                <div className="fullservices">
            {/* <div className="container"> */}
        
                <div className=" appdevelop-box">
                    <section id="mobile">
                    <div className="top-head">
                       <span className="pagehead"> Expertise</span>
                        </div>
                <div className="sevices-head" >Mobile Application Development</div>
                <div className="row contentrow">
                    <div className='col-lg-2'></div>
                    <div className="col-lg-8" id="service-para">
                <p className="app-content">Our high-quality light weight solutions are created to work across different platforms delivering the next generation digital user experience that will bring your business to the next level.


</p>   </div>
                </div>
            <div className="row cntrows">
                <div className="col-lg-2"></div>
                <div className="col-lg-4">
                    <div className="fullrow">
                 {/* <img src={app} alt="" className="services-image"/> */}
                 <div className="sub-1apphead">We Incorporate</div>
                 <ul className="line-cnt">
                       <li className="li-content">UI/UX Expertise</li>
                         <li className="li-content">Data Security</li>
                        <li className="li-content">Responsive design</li>
                        <li className="li-content">Single code base to apps on both iOS & Android platforms</li>
                         <li className="li-content">Using proactive approach and agile process</li>
                 </ul></div>
                </div>
                <div className="col-lg-4">
                     
                    <div className="fullrow">
               <div className="sub-apphead">Tools</div>
                <div className="mobaligns"><br/>
                <div className="row algn-row">
                    {/* <div className="col-lg-2"></div> */}
                    <div className="col-lg-3"
                    //   id='image-container'
                      >
                        <div className="img-algn">
                     <img src={Picture7} 
                     alt="iphone_logo" title="iphone_logo"
                     className="mob-newimg"
                     />
                     </div>
                    </div>
                    <div className="col-lg-3">
                    <div className="img-algn">
                       <img src={Picture4} 
                       alt="android-logo" title="android-logo"
                       className="mob-newimg"
                       />
                       </div>
                    </div>
                    <div className="col-lg-3">
                    <div className="img-algn">
                      <img src={Picture5}
                      alt="React-native " title="React-native"
                       className="mob-newimg"
                      />
                      </div>
                    </div>
                    <div className="col-lg-3">
                    <div className="img-algn">
                        <img src={Picture1}
                         className="mob-newimg"
                         alt="visualstudio-code " title="visualstudio-code "
                         />
                         </div>
                    </div>

                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture1} className="mob-newimg"/>
                    </div> */}
                    {/* <div className="col-lg-4"  id='image-container'>
                    
                    
                    </div> */}
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture4} className="mob-newimg"/>
                    </div> */}
                    {/* <div className="col-lg-2"></div> */}
                
                </div><br/>
                <div className="row">
               
                <div className="col-lg-3" 
                
                 > <div className="img-algn">
                     <img src={Picture8} className="mob-newimg"
                     alt="X-code" title="X-code"
                     />
                    </div>
                    </div>
                
                    <div className="col-lg-3">
                        <div className="img-algn">
                      <img src={Picture6} 
                      alt="postman" title="postman"
                      className="mob-newimg"/>
                      </div>
                    </div>
                    <div className="col-lg-3">
                    <div className="img-algn">
                 <img src={sqlite} 
                  alt="sqlite" title="sqlite"
                 className="mob-newimg"/>
                 </div>
                    </div>
                    <div className="col-lg-3">
                    <div className="img-algn">
                        <img src={Picture2} 
                        alt="mysql" title="mysql"
                        className="mob-newimg"/>
                        </div>
                    </div>
                    <div className="col-lg-2"
                    
                      >
                   
                    
                    </div>
                   
                </div><br/>
                <div className="row">
               
               <div className="col-lg-3" 
               
                > <div className="img-algn">
                    <img src={Picture3} 
                     alt="visual-studio" title="visual-studio"
                    className="mob-newimg"
                    />
                   </div>
                   </div>
               
                   <div className="col-lg-3">
                       <div className="img-algn">
                     {/* <img src={Picture6} className="mob-newimg"/> */}
                     </div>
                   </div>
                   <div className="col-lg-3">
                   <div className="img-algn">
                {/* <img src={sqlite} className="mob-newimg"/> */}
                </div>
                   </div>
                   <div className="col-lg-3">
                   <div className="img-algn">
                       {/* <img src={Picture2} className="mob-newimg"/> */}
                       </div>
                   </div>
                   <div className="col-lg-2"
                   
                     >
                  
                   
                   </div>
                  
               </div>
                </div>
                </div>
                </div>
                {/* <div  className="col-lg-2 "></div> */}
            </div>
            <br/>
            
            {/* <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 iosrow">
                    <img src={iosicon} className="ios"/><br/>
                    
                
                    
                
                  
                </div>
                <div className="col-lg-2"></div>
            </div><br/> */}
       
            
            </section>
            </div>
            <div id="webapp" className="title-box"></div>
            <div className="webapplication-box" 
            id="webapp"
            >
            <div className="service-heading">Web Application and Website Development</div>
            
            <div className="row">
            <div className="col-lg-2"></div>
               
                <div className="col-lg-8" id="service-para">
                <p className="app-content">Our solutions have laser level focus on your needs using responsive, attractive and elegant design with a faster loading time that work seamlessly across different browsers and engage users at an in-depth level with their critical focus on user interface and security, and ingenuity in design while being easy to navigate. 

 
 </p>
                 
                </div>
                <div className="col-lg-2"></div>
            </div>
            
            
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-4">
                    <div className="fullrow">
                 
                 <div className="sub-1apphead">We Incorporate</div>
                 <ul className="line-cnt">
                       <li className="li-content">Use of latest and flexible Agile development model
</li>
                         <li className="li-content">Customized creative website designing with UI/UX Expertise
</li>
                        <li className="li-content">Quality standards & adherence to best coding guidelines
</li>
                        <li className="li-content">Proper use of patterns on frontend, unified clean code and APIs
</li>
                         <li className="li-content">Data Security
</li>
                 </ul></div>
                </div>
                <div className="col-lg-4">
                    <div className="fullrow">
                <div className="sub-apphead">Tools</div><br/>
                <div className="mobalign">
                <div className="row">
                    <div className="col-lg-3">
                        <img src={Picture26} 
                        alt="HTML5" title="HTML5"
                        className="mob-newimg"/>
                    </div>
                    <div className="col-lg-3" 
                    //  id='image-container'
                     >
                     
                     <img src={Picture27} 
                     alt="css3" title="css3"
                     className="mob-newimg"/>
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture1} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3" >
                    <img src={Picture28}
                    alt="javascript" title="javascript"
                    className="mob-newimg"/>
                    
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture25} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3">
                        <img src={Picture3} 
                        alt="visual-studio" title="visual-studio"
                        className="mob-newimg"/>
                    </div>
                
                </div><br/>
                <div className="row">
                <div className="col-lg-3">
                     <img src={Picture2}
                      alt="mysql" title="mysql"
                     className="mob-newimg"/>
                </div>
                <div className="col-lg-3">
                    
                     <img src={Picture6} 
                     alt="postman" title="postman"
                     className="mob-newimg"/>
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture27} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3" >
                    <img src={Picture1} 
                    alt="visualstudio-code" title="visualstudio-code"
                    className="mob-newimg"/>
                    
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture6} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3">
                       <img src={sqlite} 
                       alt="sqlite" title="sqlite"
                       className="mob-newimg"/>
                    </div>
                </div>
                </div>
                </div>
                </div>
                <div  className="col-lg-2"></div>
            </div>
               
                <br/>
            </div>
            <div id="cloudid" className="title-box"></div>
            <div className="webapplication-box" 
            id="cloudid"
            >
            <div className="service-heading">Cloud Computing</div>
            <div className="row">
            <div className="col-lg-2"></div>
               
                <div className="col-lg-8" id="service-para">
                <p className="app-content">
                Our guidance will allow you to choose appropriate cost-effective cloud services that allow you to innovate faster, be flexible with your resources, and achieve economies of scale.



 </p>
                 
                </div>
                <div className="col-lg-2"></div>
            </div>
            
            
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-4">
                    <div className="fullrow">
                 
                 <div className="CCsub-apphead">We Incorporate</div>
                 <ul className="line-cnt">
                       <li className="li-content">Business Continuity Plan
</li>
                         <li className="li-content">Application Migration
</li>
                        <li className="li-content">Data Migration
</li>
                        <li className="li-content">Quality Assurance
</li>
                         <li className="li-content">Data Security
</li>
                 </ul></div>
                </div>
                <div className="col-lg-4">
                    <div className="fullrow">
                <div className="sub-apphead">Tools</div><br/>
                <div className="mobalign">
                <div className="row">
                    <div className="col-lg-3">
                     <img src={aws} 
                     alt="aws" title="aws"
                     className="mob-newimg"/>
                    </div>
                    <div className="col-lg-3">
                     
                     <img src={amazon}
                      alt="amazon" title="amazon"
                     className="mob-newimg"/>
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={amazon} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3">
                    <img src={azure} 
                     alt="azure" title="azure"
                    className="mob-newimg"/>
                    
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={gcloud} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3">
                       <img src={gcloud}
                       alt="google-cloud" title="google-cloud"
                       className="mob-newimg"/>
                    </div>
                
                </div><br/>
                <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-2">
                     {/* <img src={Picture26} className="mob-newimg"/> */}
                    </div>
                    <div className="col-lg-2">
                    {/* <img src={Picture27} className="mob-newimg"/> */}
                    </div>
                    <div className="col-lg-2">
                    {/* <img src={Picture28} className="mob-newimg"/> */}
                    </div>
                    <div className="col-lg-2">
                    {/* <img src={Picture29} className="mob-newimg"/> */}
                    </div>
                    <div className="col-lg-2"></div>
                </div>
                </div>
                </div>
                </div>
                <div  className="col-lg-2"></div>
            </div>
            <br/>
                
            </div>

            <div id="datamig" className="title-box"></div>
            <div className="webapplication-box" 
            id="datamig"
            >
            <div className="service-heading">Data Migration</div>
            <div className="row">
            <div className="col-lg-2"></div>
               
                <div className="col-lg-8">
                <p className="app-content"> Our creative solutions help you lower your operating costs, run your infrastructure efficiently, and allow scalability as your business needs change.



 </p>
                 
                </div>
                <div className="col-lg-2"></div>
            </div>
            
            
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-4">
                    <div className="fullrow">
                 
                 <div className="DMsub-apphead">We Incorporate</div>
                 <ul className="line-cnt">
                       <li className="li-content">On Premise Data Migration
</li>
                         <li className="li-content">Cloud Data Migration
</li>
                        <li className="li-content">Storage Migration
</li>
                        <li className="li-content">Application Migration
</li>
                         <li className="li-content">Business Process Migration
</li>
                 </ul></div>
                </div>
                <div className="col-lg-4">
                    <div className="fullrow">
                <div className="sub-apphead">Tools</div><br/>
                <div className="mobalign">
                <div className="row">
                    <div className="col-lg-3">
                          <img src={oracle} className="mob-newimg"/> 
                    </div>
                    <div className="col-lg-3"  id='image-container'>
                  
                     <img src={informatica} className="mob-newimg"/>
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture2} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3"  id='image-container'>
                    <img src={Picture2} className="mob-newimg"/>
                   
                    </div>
                    {/* <div className="col-lg-2" id='image-container'>
                    <img src={IBM} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3">
                        <img src={IBM} className="mob-newimg"/> 
                    </div>
                
                </div><br/>
                <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-2">
                     {/* <img src={Picture26} className="mob-newimg"/> */}
                    </div>
                    <div className="col-lg-2">
                    {/* <img src={Picture27} className="mob-newimg"/> */}
                    </div>
                    <div className="col-lg-2">
                    {/* <img src={Picture28} className="mob-newimg"/> */}
                    </div>
                    <div className="col-lg-2">
                    {/* <img src={Picture29} className="mob-newimg"/> */}
                    </div>
                    <div className="col-lg-2"></div>
                </div></div>
                </div>
                </div>
                <div  className="col-lg-2"></div>
            </div>
            <br/>
                
            </div>
           
                <div id="dataint" className="title-box"></div>
            <div className="webapplication-box" id="dataint">
                <div className="service-heading">Business Intelligence & Analytics</div>
                <div className="row">
                <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                    <p className="app-content">Our solutions tailor made to your needs will deliver deep insight allowing you to make better decisions and drive cost down, improve performance and expand your business.



</p>
                    </div>
                    {/* <div className="col-lg-4">
                    <img src={inteligent} alt="" className="services-image"/>
                    </div> */}
                    <div className="col-lg-2"></div>
                </div>
                {/* <div className="sub-apphead">Our Data Intelligence Services Offer Powerful Data Insights</div> */}
                
                <div className="row ">
                <div className="col-lg-2"></div>
                    <div className="col-lg-4 ">
                        <div className="fullrow">
                    <div className="BIsub-apphead">We Incorporate</div>
                    <ul className="line-cnt">
                    <li className="li-content">Reports & Dashboards
</li>
                    <li className="li-content">Predictive Analytics
</li>
                    <li className="li-content">Key Influencer Analytics
</li>
                    <li className="li-content">Plug and Play with any data source

</li>
                    </ul>

                    {/* <p className="app-develophead">Power BI</p>
                    <p className="subhead-appcontent">
                    Our Data Interline optimize the data processing by Power BI to fetch and represent information in dynamic and interactive data visualization.
 We provide powerful high-end business solutions by combining Power BI and Azure analytics services. Genc Services Offer Powerful Data Insights.

                    </p>
                    <p className="app-develophead">Reports & Dashboards</p>
                    <p className="subhead-appcontent">Create, edit and share - right from simple visualization to advanced charts and complex tabular & cross tab reports, drag & drop to create stunning dashboards and reports.
                    </p>
                    <p className="app-develophead">Predictive Analytics</p>
                    <p className="subhead-appcontent">Zero time learning, interactive module for advanced analytics Create, Share and Use Predictive Models, without any coding,
 without any data science background. Transform your business users to Citizen Data Scientists.
                     </p>
                     <p className="app-develophead">Key Influencer Analytics</p>
                     <p className="subhead-appcontent">Know what matters in your data! Understand impact of influencers on your target with simple English language interpretations and interactive visualizations.</p><br/>
                     <p className="app-develophead">Build, Merge, Navigate and Negotiate Your Data</p><br/>
                      <p className="subhead-appcontent">From multiple sources for multiple functions. Bring data from multiple sources and Build what you need! Build Cross Tabs, Tabular & Print Reports.</p>
                         */}
                         </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="fullrow">
                        <div className="boxborder">
                    <div className="sub-apphead">Tools</div><br/>
                    <div className="mobalign">
                    <div className="row">
                        
                    <div className="col-lg-3">
                     <img src={Picture11} className="mob-newimg"/>
                    </div>
                    <div className="col-lg-3"  id='image-container'>
                    
                     <img src={Picture2} className="mob-newimg"/>
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                     <img src={Picture2} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3"  id='image-container'>
                     <img src={Picture9} className="mob-newimg"/>
                     
                    </div>
                    {/* <div className="col-lg-2" id='image-container'>
                     <img src={Picture12} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3">
                        <img src={Picture12} className="mob-newimg"/>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
                <br/>
                
            </div>
            
            <div id="digit" className="title-box"></div>
                <div className="webapplication-box" id="digit"> 

                <div className="letter">

                <div className="service-heading">Digital Marketing</div>
                <div className="row">
                <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                    <p className="app-content">Our expertise by utilizing the latest web tools in the domain of SEO, Content Management and Social Media Marketing will help expand your company’s digital reach and grow your business.



                    {/* <br/><br/>
                    The “4Ps”— price, product, promotion, and place — have been the 
                    cornerstone of a successful marketing strategy for over 50 years. */}
                     </p>
                    </div>
                    {/* <div className="col-lg-4">
                    <img src={market} alt="" className="services-image"/>  
                    </div> */}
                    <div className="col-lg-2"></div>
                </div>
                <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-4 ">
                    <div className="fullrow">
                 {/* <img src={app} alt="" className="services-image"/> */}
                 <div className="Disub-apphead">We Incorporate
</div>
                 <ul className="line-cnt">
                       <li className="li-content">Social Media Marketing</li>
                         <li className="li-content">Paid Advertising</li>
                        <li className="li-content">Search Engine Optimization</li>
                        <li className="li-content">Content Management Services</li>
                         <li className="li-content">Product Branding</li>
                 </ul></div>
                </div>
                <div className="col-lg-4">
                    <div className="fullrow">
                <div className="sub-apphead">Tools</div><br/>
                <div className="mobalign">
                <div className="row">
                <div className="col-lg-3">
                     <img src={Picture16}
                     alt="ubersuggest" title="ubersuggest"
                      className="mob-newimg"/>
                </div>
                    <div className="col-lg-3"  id='image-container'>
                    
                     <img src={Picture14}
                     alt="google-analytics" title="google-analytics"
                     className="mob-newimg"/>
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture14} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3"  id='image-container'>
                    <img src={Picture15} 
                    alt="stack" title="stack"
                    className="mob-newimg"/>
                    
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture16} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3">
                        <img src={Picture13} 
                        alt="google-adds" title="google-adds"
                        className="mob-newimg"/>
                    </div>
                </div><br/>
                <div className="row">
                <div className="col-lg-3">
                    <img src={Picture18}
                    alt="canva" title="canva"
                    className="mob-newimg"/>
                </div>
                {/* <div className="col-lg-2">
                   
                    </div> */}
                <div className="col-lg-3"  id='image-container'>
                     
                     {/* <img src={Picture18} className="mob-newimg"/> */}
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture18} className="mob-newimg"/>
                    </div> */}
                    
                    <div className="col-lg-3">
                   
                    </div>
                    <div className="col-lg-3"></div>
                </div></div>
                </div>
                </div>
                <div className="col-lg-2"></div>
            </div><br/>
            
                {/* <div className="sub-apphead">Types of Digital Marketing Channels</div><br/>
                <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8">
                <p className="app-develophead">Website Marketing</p>
                    <p className="subhead-appcontent">
                    A website is the centrepiece of all digital marketing activities. It is a very powerful channel on its own, but it’s also the medium needed to execute a variety of online marketing campaigns.
                    </p>
                    <p className="app-develophead">Affliate Marketing</p>
                    <p className="subhead-appcontent">
                    Affiliate marketing is one of the oldest forms of marketing, and the internet has brought new life to this old
                     standby. With affiliate marketing, influencers promote other people’s products and get a commission every time
                      a sale is made or a lead is introduced. Many well-known companies like 
                    Amazon have affiliate programs that pay out millions of dollars per month to websites that sell their products.
                    </p>
                    <p className="app-develophead">Email Marketing</p>
                    <p className="subhead-appcontent">Email marketing is still one of the most effective digital marketing channels. 
                    Many people confuse email marketing with spam email messages, but that’s not what email marketing is all about. 
                    This type of marketing allows companies to get in touch with potential customers and anyone interested in their brands.<br/><br/>
                    Many digital marketers use all other digital marketing channels to add leads to their email lists and then,
                     through email marketing, they create customer acquisition funnels to turn those leads into customers.
                    </p>
                    <p className="app-develophead">Video Marketing</p>
                    <p className="subhead-appcontent">YouTube is one of the most popular search engines in the world. A lot of users are turning to YouTube before making a buying decision, to learn something, read a review, or just to relax.
                     </p>
                     <p className="app-develophead">SMS Messaging</p>
                    <p className="subhead-appcontent">Companies and non-profit organizations also use SMS or text messages to send 
 information about their latest promotions or give opportunities to willing customers. Political candidates 
 running for office also use SMS message 
 campaigns to spread positive information about their own platforms.
                     </p>
                    <p className="app-develophead">Social Media Marketing</p>
                    <p className="subhead-appcontent">The primary goal of a social media marketing campaign is brand awareness and 
                    establishing social trust. As you go deeper into social
                     media marketing, you can use it to get leads or even as a direct sales channel.
                     </p>
                     
                        </div>
                        <div className="col-lg-2"></div>
                        </div> */}



                        </div>  
               
                
                </div>

            
            {/* <div className="webapplication-box" id="reportings">
            <div className="service-heading">Reporting</div><br/><br/>
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-4">
                <p className="app-content">Data reporting is the process of collecting and submitting data which gives rise 
                to accurate analyses of the facts on the ground<br/> inaccurate data reporting can lead to vastly uninformed
                 decision-making based on erroneous evidence. Different from data analysis that transforms data and information
                  into insights, data reporting is the previous step that translates raw data into information.[1] When data is
                   not reported, 
                the problem is known as underreporting; the opposite problem leads to false positives.</p>
                </div>
                <div className="col-lg-4">
                <img src={reportingimg} alt="" className="services-image"/>
                </div>
                <div className="col-lg-2"></div>
                </div>  
                <div className="sub-apphead"></div><br/>
                <div className="row">
                <div className="col-lg-2"></div>
                 <div className="col-lg-8">
                  <p className="app-develophead">Reporting Skills</p> 
                 <p className="subhead-appcontent">The most effective business analysts master certain skills. An excellent
                  business analyst must be able to prioritize the most relevant information. They must be extremely thorough 
                  and detail-oriented; there’s no room for error in data reports. Another useful skill is the ability to process
                   and collate large amounts of information. And finally,
                  being able to arrange the data and display it in an easy-to-read format is key for all data reporters.</p><br/>
                 
                  <p className="app-develophead">Improve Your Reporting</p><br/>
                  <p className="subhead-appcontent">Static reporting, while it can be accurate and effective, does have its limits.
                   One example is the lack of real-time insights. If presented with a large amount of data to wrangle into
                    useful and actionable form, a  report helps present to senior management or the sales team, direction on
                     future moves. However, the layout, data, and formulas may be outdated if not presented in a timely manner. 
                     Also, each data source must be manually inputted into your spreadsheet,
                      while a BI platform can handle multiple inputs and visualize complex and dynamic data with ease.<br/><br/>
                      BI tools are agile and flexible enough to grow with your company’s needs. Sisense is easy to use and will 
                      allow you to mash up your data any way 
                      you want to derive actionable insights — without involving the IT department.<br/><br/>
                      Data reporting is key to your company’s business intelligence. Be sure to choose a tool that will help
                       you get the most insights out of your data and make them accessible to all. The more access to data your
                        organization has, the more agile it can be. That can help your organization stay relevant in an increasingly
                         competitive and changing market. An effective data reporting system will inform thoughtful 
                      decisions that could lead your company in new directions and to greater revenue sources.
                  </p>
                 
                    
                 </div>
                 <div className="col-lg-2" ></div>
                </div>
                
            </div> */}
           <div id="quality" className="title-box"></div>
            <section id="quality">
            <div className="webapplication-box" >
            <div className="service-heading">Quality Assurance</div> 
            <div className="row">

                <div className="col-lg-2"></div>
                <div className="col-lg-8"> <p className="app-content">We offer high quality assurance and software testing solutions using the latest technologies to enhance the quality of the products that are built to fit in within your existing environment with minimal disruption encompassing speed and sanctity of security.

</p>
</div>
                <div className="col-lg-2"></div>
            {/* <div className="col-lg-2"></div>
                <div className="col-lg-4">
                <img src={approval}alt="" className="services-image"/>
                </div>
                <div className="col-lg-4">
                <p className="app-content">In today’s hyper-connected world, it’s essential to implement an in-built quality assurance process to accelerate time-to-market and deliver superior customer experience. In fact, Quality Assurance as a strategic & transformative function, has become a Quality Enabler than a Gatekeeper.
                 

 </p></div><div className="row">
 <div className="col-lg-2"></div>
 <div className="col-lg-8"> <p className="app-content">KDP Tech Assurance and Quality Engineering practice, helps clients achieve continuous quality across their run, change and transformation initiatives. Our Assurance Platform leverages Automation, DevOps practices, Cloud, Mobile and Advanced Data Analytics, which can help enterprises in their digital transformation journey. It combines principles of process re-engineering with cognitive computing and AI, to boost the efficiency of the test authoring process and its execution. It is built to help testing teams reduce their effort significantly, by embedding our platform and cognitive solution accelerators across the testing value chain. We are pioneers in providing Testing-as-a-Service (TaaS) to help businesses access on-demand validations, eliminating the cost of test environment management.</p></div>
 <div className="col-lg-2"></div>
 </div>
                
                <div className="col-lg-2"></div> */}
            </div>
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-4">
                    <div className="fullrow">
                 
                 <div className="Qusub-apphead">We Incorporate
</div>
                 <ul className="line-cnt">
                       <li className="li-content">Web-Service and Backend Automation
</li>
                         <li className="li-content">Mobile Automation Testing
</li>
                        <li className="li-content">Open-Source Testing
</li>
                        {/* <li className="li-content">Content Management Services</li>
                         <li className="li-content">Product Branding</li> */}
                 </ul></div>
                </div>
                <div className="col-lg-4">
                    <div className="fullrow">
                <div className="sub-apphead">Tools</div><br/>
                <div className="mobalign">
                <div className="row">
                     <div className="col-lg-3">
                   <img src={Picture19}
                   alt="jrosoftware " title="jrosoftware "
                   className="mob-newimg"/>
                     </div>
                    <div className="col-lg-3"  id='image-container'>
                     
                     <img src={Picture3} 
                     alt="visual-studio" title="visual-studio"
                     className="mob-newimg"/>
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture6} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3"  id='image-container'>
                    <img src={Picture6} 
                    alt="postman" title="postman"
                    className="mob-newimg"/>
                    
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture16} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3">
                      <img src={Picture16}
                      alt="ubersuggest" title="ubersuggest"
                      className="mob-newimg"/>
                    </div>
                </div>
                </div>
                <br/>
                
                </div>
                </div>
                <div className="col-lg-2"></div>
            </div>
           <br/>
            </div></section>
            <div id="appser" className="title-box"></div>
            <div className="webapplication-box" id="appser">
            <div className="service-heading">Application Services and Modernization</div>
            <div className="row">
            <div className="col-lg-2"></div>
                <div className="col-lg-8">
                   
                <p className="app-content">Our expertise in customized software development solutions will be handy in the process of creating new applications or migrate your outdated web and mobile applications to latest technologies.


</p>
                </div>
               
                <div className="col-lg-2"></div>
                
            </div>
            <div className="row mor-row">
         
             <div className="col-lg-2"></div>
                <div className="col-lg-4">
                    <div className="fullrow">
              
                 <div className="sub-1apphead">We Incorporate
</div>
                 <ul className="line-cnt">
                       <li className="li-content">Innovative Modern Architecture allowing you to scale

</li>
                         <li className="li-content">Excellent Data Security

</li>
                        <li className="li-content">Build on the already in place Core Infrastructure

</li>
                       
                 </ul></div>
                </div>
                <div className="col-lg-4">
                    <div className="fullrow">
                <div className="sub-apphead">Tools</div><br/>
                <div className="mobalign">
                <div className="row">
                     <div className="col-lg-3">
                      <img src={Picture7} 
                      alt="ios" title="ios" 
                      className="mob-newimg"/>
                     </div>
                    <div className="col-lg-3"  id='image-container'>
                    
                     <img src={Picture4}
                     alt="android" title="android"
                     className="mob-newimg"/>
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture3} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3"  id='image-container'>
                    <img src={Picture5} 
                    alt="ReactNative" title="ReactNative"
                    className="mob-newimg"/>
                    
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture2} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3">
                       <img src={Picture8}  alt="xcode" title="xcode"  className="mob-newimg"/>
                    </div>
                </div><br/>
                <div className="row">
                     <div className="col-lg-3">
                         <img src={sqlite} alt="sqlite" title="sqlite"  className="mob-newimg"/>
                     </div>
                    <div className="col-lg-3"  id='image-container'>
                     
                     <img src={Picture6} alt="postman" title="postman" className="mob-newimg"/>
                    </div>
                  
                    <div className="col-lg-3"  id='image-container'>
                    <img src={Picture2}  alt="mysql" title="mysql" className="mob-newimg"/>
                    
                    </div>
                  
                    <div className="col-lg-3">
                       <img src={Picture3}  alt="visual studio" title="visual studio" className="mob-newimg"/>
                    </div>
                </div><br/>
                <div className="row">
                     <div className="col-lg-3">
                         <img src={Picture1}  alt="visual studio code" title="visual studio code" className="mob-newimg"/>
                     </div>
                    <div className="col-lg-3"  id='image-container'>
                     
                     {/* <img src={Picture6} className="mob-newimg"/> */}
                    </div>
                  
                    <div className="col-lg-3"  id='image-container'>
                    {/* <img src={Picture2} className="mob-newimg"/> */}
                    
                    </div>
                  
                    <div className="col-lg-3">
                       {/* <img src={Picture3} className="mob-newimg"/> */}
                    </div>
                </div>
                </div>
                <br/>

                
                </div>
                </div>
                <div className="col-lg-2"></div>
            </div>
            <br/>
            
              </div> 
              <div id="ites" className="title-box"></div>
              <div className="webapplication-box">
                <section id="ites">
            <div className="service-heading" >ITES</div>
            
                
                <div className="row">
                <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                   
                 <div className="app-content">

                 We provide expert advice across a range of platforms and technologies in all key areas of the IT infrastructure from desktop to data center.




         

</div><br/>


                  
                  

                    </div>
                    <div className="col-lg-2"></div>
                </div>
                <div className="row itescnt">
         
             <div className="col-lg-2"></div>
                <div className="col-lg-4">
                    <div className="fullrow">
              
                 <div className="ITsub-apphead">We Incorporate
</div>
                 <ul className="line-cnt">
                       <li className="li-content">Technical Consulting


</li>
                         <li className="li-content">Requirement Analysis


</li>
                        <li className="li-content">
Enterprise IT Policy and Planning


</li>
<li className="li-content">
Integration and Consolidation



</li>
<li className="li-content">
Software Interface and Configuration



</li>
<li className="li-content">
Product Integration



</li>



                       
                 </ul></div>
                </div>
                <div className="col-lg-4">
                    <div className="fullrow">
                <div className="sub-apphead">Tools</div><br/>
                <div className="mobalign">
                <div className="row">
                     <div className="col-lg-3">
                        <img src={Picture31}  alt="zoom" title="zoom" className="mob-newimg"/> 
                     </div>
                    <div className="col-lg-3"  id='image-container'>
                     
                     <img src={Picture32}  alt="uber conference" title="uber conference" className="mob-newimg"/>
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture32} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3"  id='image-container'>
                    <img src={Picture34}  alt="microsoft teams" title="microsoft teams" className="mob-newimg"/>
                    
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture34} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3">
                        <img src={Picture15}  alt="stack" title="stack" className="mob-newimg"/>
                    </div>
                </div><br/>
                <div className="row">
                     <div className="col-lg-3">
                        <img src={Picture35}  alt="office" title="office" className="mob-newimg"/> 
                     </div>
                    <div className="col-lg-3"  id='image-container'>
                     
                     <img src={Picture36}  alt="email" title="email" className="mob-newimg"/>
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture36} className="mob-newimg"/>
                    </div> */}
                    {/* <div className="col-lg-2">
                    <img src={Picture7} className="mob-newimg"/>
                    </div> */}
                    {/* <div className="col-lg-2">
                    <img src={Picture4} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3"></div>
                </div></div>
                <br/>

                
                </div>
                </div>
                <div className="col-lg-2"></div>
                
            </div>
            <br/>
            
                </section>
                
                </div>
                <div id="tech" className="title-box"></div>
              <div className="webapplications-box">
                <section id="tech">
            <div className="service-heading" >Staff Augmentation</div>
            
                
                <div className="row">
                <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                   
                 <p className="app-content">
                 We can provide staff with latest skills across a range of experience levels that can become a part your team and work under your leadership.



                 {/* Do you need a content writer or a content writing service for your business consulting services?
 Are you looking for copywriting services to increase the conversion rate of your landing page or your email marketing campaigns?
As a business consulting service or as a business consultant, you normally provide help to organizations and businesses to improve their performance and efficiency.
<br/>
With Our expert content writing and copywriting services We will make it easier for people to find your website on Google and other search engines.<br/>
And once people are on your website, we will help you convey to them that your business consulting expertise can be trusted, and you can help them grow.<br/>
Efficient and quality content writing services help you at two fronts: improve your SEO – search engine rankings – and improve overall engagement rate on your website through useful and relevant content.<br/>
To improve your organic search engine rankings, you need to publish high-quality content on your website or blog on an ongoing basis. */}

</p><br/>

                  
                  

                    </div>
                    <div className="col-lg-2"></div>
                </div>
                <div className="row">

                     <div className="col-lg-2"></div>
                <div className="col-lg-4">
                    <div className="fullrow">
                 {/* <img src={app} alt="" className="services-image"/> */}
                 <div className="STsub-apphead">We Incorporate
</div>
                 <ul className="line-cnt">
                       <li className="li-content">Mobile Application Development


</li>
                         <li className="li-content">Web Application and Website Development


</li>
                        <li className="li-content">Business Intelligence and Analytics


</li>
<li className="li-content">Digital Marketing/SEO



</li>
<li className="li-content">Quality Assurance



</li>
<li className="li-content">Application Development and Modernization



</li>
<li className="li-content">ITeS




</li>
                        {/* <li className="li-content">Content Management Services</li>
                         <li className="li-content">Product Branding</li> */}
                 </ul></div>
                </div>
             
             
            
                <div className="col-lg-4">
                <div className="fullrow"> 
                        
                <div className="sub-apphead">Tools</div><br/>
                <div className='staffalign'>
                <div className="row">
                     <div className="col-lg-3">
                         <img src={Picture7}  alt="ios" title="ios" className="mob-newimg"/>
                     </div>
                    <div className="col-lg-3"  id='image-container'>
                     
                     <img src={Picture4}  alt="android" title="android" className="mob-newimg"/>
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture5} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3"  id='image-container'>
                    <img src={Picture8}  alt="xcode" title="xcode" className="mob-newimg"/>
                    
                    </div>
                    <div className="col-lg-3">
                       <img src={Picture1}  alt="Visualstudio code" title="Visualstudio code" className="mob-newimg"/> 
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture7} className="mob-newimg"/>
                    </div> */}
                    </div><br/>
                    {/* <div className="col-lg-1"></div> */}
                {/* </div><br/> */}
                {/* <div className="row"> */}
                     {/* <div className="col-lg-2"></div> */}
                     <div className="row">
                     <div className="col-lg-3">
                         <img src={Picture11}  alt="tableu" title="tableu" className="mob-newimg"/>
                     </div>
                    <div className="col-lg-3"  id='image-container'>
                     
                     <img src={Picture6}  alt="postman" title="postman" className="mob-newimg"/>
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture27} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3"  id='image-container'>
                    <img src={Picture16}  alt="ubersuggest" title="ubersuggest" className="mob-newimg"/>
                   
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture28} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3">
                     <img src={Picture9}  alt="postman" title="postman" className="mob-newimg"/>
                    </div>
                </div>
                <br/>
                <div className="row">
                     <div className="col-lg-3">
                         <img src={sqlite}  alt="sqlite" title="sqlite" className="mob-newimg"/>
                     </div>
                    <div className="col-lg-3"  id='image-container'>
                     
                     <img src={Picture2}  alt="mysql" title="mysql" className="mob-newimg"/>
                    </div>
                    {/* <div className="col-lg-2"  id='image-container'>
                    <img src={Picture35} className="mob-newimg"/>
                    </div> */}
                    <div className="col-lg-3"  id='image-container'>
                    <img src={Picture3}  alt="visualstudio code" title="visualstudio code" className="mob-newimg"/>
                    
                    </div>
                    <div className="col-lg-3"  id='image-container'>
                    
                    <img src={Picture12}  alt="excel" title="excel" className="mob-newimg"/>
                    </div>
                    {/* <div className="col-lg-2" id='image-container'>
                    <img src={aws} className="mob-newimg"/>
                    </div> */}
                    </div><br/>
                    {/* <div className="col-lg-2"></div> */}
                {/* </div><br/> */}
                {/* <div className="row"> */}
                     {/* <div className="col-lg-2"></div> */}
                     <div className="row">
                     <div className="col-lg-3">
                            <img src={oracle}  alt="oracle" title="oracle" className="mob-newimg"/>
                     </div>
                    <div className="col-lg-3"  id='image-container'>
                  
                     <img src={informatica}  alt="informatica" title="informatica" className="mob-newimg"/>
                    </div>
                   
                    <div className="col-lg-3"  id='image-container'>
                    {/* <img src={Picture32} className="mob-newimg"/> */}
                    </div>
                    <div className="col-lg-3">
                    {/* <img src={Picture34} className="mob-newimg"/> */}
                    </div>
                   
                </div>
                {/* <br/> */}
                {/* <div className="row">
                     <div className="col-lg-3">
                            <img src={Picture35} className="mob-newimg"/>
                     </div>
                    <div className="col-lg-3"  id='image-container'>
                  
                     <img src={Picture36} className="mob-newimg"/>
                    </div>
                   
                    <div className="col-lg-3"  id='image-container'>
                    <img src={Picture11} className="mob-newimg"/>
                    </div>
                    <div className="col-lg-3">
                    <img src={Picture9} className="mob-newimg"/>
                    </div>
                
                </div> */}
                {/* <br/> */}
                {/* <div className="row">
                     <div className="col-lg-3">
                            <img src={aws} className="mob-newimg"/>
                     </div>
                    <div className="col-lg-3"  id='image-container'>
                  
                     <img src={Picture18} className="mob-newimg"/>
                    </div>
                   
                    <div className="col-lg-3"  id='image-container'>
                    
                    </div>
                    <div className="col-lg-3">
                    
                    </div>
                
                </div> */}
                {/* <div className="row">
                     <div className="col-lg-2"></div>
                    <div className="col-lg-1">
                     <img src={Picture13} className="mob-newimg"/>
                    </div>
                    <div className="col-lg-1">
                    <img src={Picture14} className="mob-newimg"/>
                    </div>
                    <div className="col-lg-1">
                    <img src={Picture31} className="mob-newimg"/>
                    </div>
                    <div className="col-lg-1">
                    <img src={gcloud} className="mob-newimg"/>
                    </div>
                    
                    <div className="col-lg-1">
                     <img src={oracle} className="mob-newimg"/>
                    </div>
                    <div className="col-lg-1">
                    <img src={IBM} className="mob-newimg"/>
                    </div>
                    <div className="col-lg-1">
                    <img src={aws} className="mob-newimg"/>
                    </div>
                    <div className="col-lg-1">
                    <img src={amazon} className="mob-newimg"/>
                    </div>
                    <div className="col-lg-1"></div>
                </div> */}
                
                {/* <div className="row">
                <div className="col-lg-2"></div>
                    <div className="col-lg-1">
                     <img src={Picture34} className="mob-newimg"/>
                    </div>
                    <div className="col-lg-1">
                    <img src={Picture35} className="mob-newimg"/>
                    </div>
                    <div className="col-lg-1">
                    <img src={Picture12} className="mob-newimg"/>
                    </div>
                    <div className="col-lg-1">
                    <img src={Picture36} className="mob-newimg"/>
                    </div>
                  
                    <div className="col-lg-1">
                    <img src={Picture11} className="mob-newimg"/>
                    </div>
                    <div className="col-lg-1">
                    <img src={Picture16} className="mob-newimg"/>
                    </div>
                    <div className="col-lg-1">
                    <img src={Picture32} className="mob-newimg"/>
                    </div>
                    <div className="col-lg-2">
                    <img src={Picture15} className="mob-newimg"/>
                    </div>
                </div> */}
                
                {/* <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-1">
                    <img src={Picture17} className="mob-newimg"/>
                    </div>
                    <div className="col-lg-1">
                    <img src={informatica} className="mob-newimg"/>
                    </div>

                    <div className="col-lg-1">
                    <img src={azure} className="mob-newimg"/>
                    </div>
                </div> */}
                </div>
                </div>
                </div>
                </div>
            
                {/* <div className="col-lg-2"></div> */}
                
                <br/><br/>
                </section>
                
                </div>

               
            </div>
        <Arrow/>
        <Footer/>
        </div>
    )
}
export default Services;