import React from 'react'
import {Fade,Zoom,Flip} from 'react-reveal';
import Footer from '../Components/Footer'
import { Carousel } from 'react-bootstrap'
import celeb1 from '../image/celeb1.png'
import celeb2 from '../image/celeb2.png'
import lifetour from '../image/lifetour.png'
// import diwali from '../image/diwali.png'
import lifetwo from '../image/lifetwo.png'
import lifethree from '../image/lifethree.png'
import lifefour from '../image/lifefour.png'
import Header from '../Components/Header'
 function Lifehere() {
    return (
        <div>
         <Header/>  
         <div className="firstabout" id="lifetop"></div>
         <div className="container lifehere">
  <div className="above-content" id="life">
<p className="above-head">Life Here</p>
</div>
<div className="lifecnt">
<p className="above-text">We are a family of self motivated, awesome, fun loving people who are out to make the world a better place!!!</p>
<p className="above-text">You just don't work here you become a part of the family</p>
<p className="above-text">We grow together in an non-hierarchial, open, flexible, stress free and pleasurable work environment, where work</p>
<p className="above-text">life balance is not a myth. We live it each day!!</p>
<p className="above-text">Sound mind, sound body and happy family are not just words for us, we encourage you to make them happen</p>
<p className="above-text">We provide opporunities to grow your knowledge, challenge you to be the best and enhance your career</p>
  </div>
<br/>
<Carousel fade prevLabel={false} nextLabel={false} indicators={false} interval={2000} className="last-carousel">
          
  <Carousel.Item   className="item">
    <Zoom>
    <img
      className="d-block w-100"
      src={celeb1}
      alt="First slide" id="boot-img"
    />
       </Zoom>             
  </Carousel.Item>
   <Carousel.Item  className="item">
     <Zoom>
    <img
      className="d-block w-100"
      src={celeb2}
      alt="Second slide" id="boot-img"
    />
    </Zoom>

  </Carousel.Item >
  <Carousel.Item  className="item">
     <Zoom>
    <img
      className="d-block w-100"
      src={lifetour}
      alt="Second slide" id="boot-img"
    />
    </Zoom>

  </Carousel.Item >
   
  {/* <Carousel.Item  className="item">
    < Zoom>
    <img
      className="d-block w-100"
      src={celeb}
      alt="Third slide" id="boot-img"
    />
    </Zoom>
  </Carousel.Item>  */}
  {/* <Carousel.Item  className="item">
    < Zoom>
    <img
      className="d-block w-100"
      src={lifeone}
      alt="fouth slide" id="boot-img"
    />
    </Zoom>
  </Carousel.Item>  */}
</Carousel>
</div> <br/> 
<Footer/>
        </div>
    )
}
export default Lifehere;