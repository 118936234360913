import React, { useState, useEffect } from 'react';
import {  Link } from "react-router-dom";
import logo3 from '../image/logo3.png'
import '../Additionalcss/Header.css'
import {Fade} from 'react-reveal';
import { NavHashLink,HashLink} from 'react-router-hash-link';
function Header() {
    const [bars, setbars] = useState(true)
    const [path, setpath] = useState("")
    
    function sidebar() {
        setbars(!bars)
    }

      const [actnav, setactnav]=useState('')   
    //   const[w,setw]=useState(false)
 
    useEffect(() => {

        
      
      },[]);

 function link(values){
    setactnav(values);  
    var size = window.screen.width
console.log("link");
   
    if(document.getElementById("goto")!==null){
     
        document.getElementById("goto").click();
 }
}
   
function close(){
    if(window.screen.width>=952){
        document.getElementById("goto").click();
        
    }
   else{

   } 

}
   
          
var w = window.innerWidth;
//  this.state={w:''}
    return (
<div className>
    
        {/* <nav className="new-navbar" >
        <div className="newlogo" style={{float:"left",justifyContent:"center"}}>
            
            </div>

        <ul className="nav-link" style={{float:"right",display:"flex",flexDirection:"row"}}>&nbsp;&nbsp;&nbsp;
            <li className="nav-item"> 
            
            </li>&nbsp;&nbsp;&nbsp;
            <li className="nav-item">
               
                </li>&nbsp;&nbsp;&nbsp;
            <li className="nav-item">
               
                </li>&nbsp;&nbsp;&nbsp;
            <li className="nav-item">
               
                </li>&nbsp;&nbsp;&nbsp;
        </ul>
    </nav> */}
        <div className="header-body">
           
        <div>
        <nav className="header-nav active" id="header-nav">
            <input type="checkbox" id="check" />
                <label htmlFor="check" className="checkbtn">
                {bars === true ?
                <i className="fas fa-stream" onClick={sidebar}></i>
                :                
                <i className="fas fa-times" id="goto" onClick={sidebar}></i>}
                </label> 
            <div  className="header-container-fluid">
            <Link className="ul-link" className=" logo header-logo" to="/">
                
                
                <span className="LogoName"><img style={{height:"53px"}} src= {logo3}/></span></Link>
            </div>
            <ul  className="header-ul" id="navbar">
<span className="d">      
<li className="header-lists">
    
   <a   className="header-list">Company</a> 
   
    </li>
<Fade up duration={500} distance={"30%"}>
<div className="d-content">
<div className="row megarow">
 
    <div className="col-lg-3"></div>
    <div className="col-lg-3">
<div className="column">
   <NavHashLink  to="/About#top" onClick={()=>link(14)}><a href="#">About&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></a> </NavHashLink>
   <NavHashLink  to="/#team"><a href="#" onClick={()=>link(15)}>Team&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></a></NavHashLink>
   <NavHashLink  to="/Lifehere#lifetop" onClick={()=>link(16)}><a href="#">Life Here&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></a> </NavHashLink>
 </div>
  
  </div>
  <div className="col-lg-3">

  <div className="column">
  <NavHashLink  to="/Givingback#givetop" onClick={()=>link(17)}><a href="#">Giving Back&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></a></NavHashLink>
  <NavHashLink  to="/Careers#careertop" onClick={()=>link(18)}><a href="#">Careers&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></a></NavHashLink>
  {/*<NavHashLink  to="/Contactus"><a href="#">Contact Us&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></a></NavHashLink>*/}
   </div>
  </div>

  <div className="col-lg-3"></div>
  </div>
  </div>  
</Fade>
</span>

  <span className="d">
         <li className="header-lists"> 

         {w<952?<span>Expertise</span>:<HashLink to="/#serv-sec"onClick={()=>link(2)} className={actnav === 2 ? "header-list act" : "header-list"} >Expertise</HashLink>}


       
     {/* {w<952?<span
          onClick={()=>link(2)} className={actnav === 2 ? "header-list act" : "header-list"}
          >          
         Expertise
         </span> :<HashLink to="/#serv-sec"
         onClick={()=>link(2)} className={actnav === 2 ? "header-list act" : "header-list"}         
        >
        Expertise
        </HashLink>         
         } */}
         
         </li>
<Fade up duration={500} distance={"30%"}>
<div className="d-content">
<div className="row megarow">
    <div className="col-lg-3">
    <div className="column">
        <NavHashLink  to="/Services#mobile" onClick={()=>link(6)} ><a href="#" > Mobile Application Development&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></a> </NavHashLink>
        <NavHashLink  to="/Services#webapp"onClick={()=>link(7)}><a href="#">Web Application & Website Development&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></a></NavHashLink>
          </div>   
    </div>
    <div className="col-lg-2">
<div className="column">
   <NavHashLink  to="/Services#cloudid"onClick={()=>link(8)}> <a href="#">Cloud Computing
&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></a> </NavHashLink>
<NavHashLink  to="/Services#datamig"onClick={()=>link(14)}> <a href="#">Data Migration&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></a> </NavHashLink>

   
  
  
  </div>
  </div>
  <div className="col-lg-3">
  <div className="column">
        <NavHashLink  to="/Services#dataint"onClick={()=>link(15)}> <a href="#">Business Intelligence & Analytics&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></a> </NavHashLink>
   <NavHashLink  to="/Services#digit"onClick={()=>link(9)}><a href="#">Digital Marketing&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></a></NavHashLink>  

 
 
 
  </div>
  </div>
  <div className="col-lg-2">
      <div className="column">
          <NavHashLink  to="/Services#quality"onClick={()=>link(10)}><a href="#">Quality Assurance&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></a></NavHashLink>
  <NavHashLink  to="/Services#appser"onClick={()=>link(11)}><a href="#">Application Services & Modernization&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></a></NavHashLink>
</div>
  </div>
  <div className="col-lg-2">
      <div className="column">  
           <NavHashLink  to="/Services#ites"onClick={()=>link(12)}><a href="#">IT Enabled Services&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></a></NavHashLink>

      <NavHashLink  to="/Services#tech"onClick={()=>link(13)}><a href="#">Staff Augmentation&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></a></NavHashLink>
      </div>
  </div>
  </div>
  </div>
  </Fade>
  </span>

  <li className="header-lists"><NavHashLink onClick={()=>link(3)} className={actnav ===3? "header-list act" : "header-list"}    to="/Technologies#techtop">Technologies</NavHashLink></li>
  
  <li className="header-lists"><NavHashLink onClick={()=>link(4)} className={actnav === 4 ? "header-list act" :  "header-list"}    to="/#product">Products</NavHashLink></li>
 <li className="header-lists"><NavHashLink onClick={()=>link(5)} className={actnav === 5 ? "header-list act" :  "header-list"}    to="/Talktous#talktop">Talk to Us</NavHashLink></li>
 {/* <li className="header-lists"><NavHashLink onClick={()=>link(6)} className={actnav === 6 ? "header-list act" :  "header-list"}    to="/#give">Giving Back</NavHashLink></li> */}
{/* <li className="dropdowns">
<li className="header-lists"><NavHashLink onClick={()=>link(7)} className={actnav === 7 ? "header-list act" :  "header-list"}    to="/jobs">Careers</NavHashLink></li>
<Fade up>
<li className="dropdowns-content">
  <Link to="/Jobs" className="a">Jobs&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></Link>
  <Link to="/" className="a">Why Kdp Tech&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-caret-right"></i></Link>
 </li></Fade>
  </li> */}


  {/* <li className="header-lists"><NavHashLink onClick={()=>link(8)}   
  className={window.location.hash ==="#/Contactus" ? 'header-list act': 'header-list'}   
  to="/Contactus">Contact Us</NavHashLink></li> */}




            </ul>
        </nav>
        




    </div>   
    
        </div>
        
        </div>
    );
}
export default Header;