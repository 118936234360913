import React from 'react'
import '../Additionalcss/Footer.css'
import {  Link } from "react-router-dom";

 function Footer() {
    return (
        <div>
            <div className="container-fluid footer-content ">
                <div className="row">
                {/* <div className=" slicklg" > */}
            {/* <div className="col-lg-5 slicksm" >
                <span className="footer-icon" >
                <i class="fa-brands fa-linkedin-in"></i>&nbsp;&nbsp;&nbsp;
                <i class="fa-brands fa-twitter"></i>&nbsp;&nbsp;&nbsp;
                <i class="fa-brands fa-facebook-f"></i>&nbsp;&nbsp;&nbsp;
                <i class="fa-brands fa-instagram"></i>&nbsp;&nbsp;&nbsp;
                <i class="fa-brands fa-youtube"></i>&nbsp;&nbsp;&nbsp;
                 </span>
                 </div> */}
                 <div className="col-lg-3"></div>
                 <div className="col-lg-6">
              <div className="footer-links">
                   {/* <li className="Footer-item"><a href="#">Sitemap</a></li>&nbsp;&nbsp;&nbsp; */}
                  <span className="Footer-items" style={{color:"white"}}><a href="#"><span className="color">© Copyright 2022 All Rights reserved</span></a></span>&nbsp;&nbsp; 
              {/* <Link to="#"><span className="color">Terms &nbsp;  | </span></Link> &nbsp;  
                  <Link to="#"><span className="color"> Privacy policy </span></Link> &nbsp;&nbsp; */}
                 
              </div>
              </div>
              <div className="col-lg-3"></div>
              {/* </div> */}
              </div>
              </div>
            </div>
    
    )
}
export default Footer;