// import React from 'react'
import React,{ useEffect,useState }  from 'react'
import '../Additionalcss/Contactus.css'
import Header from '../Components/Header'
// import Contactpage from '../Components/Contactpage';
import {Fade} from 'react-reveal';
import Arrow from '../Components/Arrow'
import Footer from '../Components/Footer'
 function Contactus() {


    const[name,setName]=useState("")
    const[email,setEmail]=useState("")
    const[message,setMessage]=useState("")
    const[error,setError]=useState(false)

    //  async function handleSubmit(event){
    //     event.preventDefault();
    //    if(name.length==0){
    //    setError(true)
    //  }
    //  if (email.length==0){
    //    setError(true)
    //  }
    //  if (message.length==0){
    //   setError(true)
    // }
    //       var Name=document.getElementById("names")
    //       var Email=document.getElementById("email")
         
        
    //       const data=new FormData(event.target);
    //        console.log("submit1");
    //     //   const url="https://apitest.kdptec.com/api/Contactpage/post";
    //       const url="https://localhost:44315/api/Contactpage/post";
          
    //         let user =(localStorage.getItem('user'));
    //     const accessToken=user;
    //         const option={
    //           method:"POST",
    //           headers:{
               
    //           },
    //            body:data
    //       };
    //       const response = await fetch(url,option);
    //       const result = await response.json();
    //       if (result.status === "Success" ){
    //         alert(
    //             result.message);
       
    //         }
    //         else{
    //        alert(
    //             result.message);
    //         }
          
    //   }
    return (
        <div>
            <Header/><br/><br/><br/><br/><br/><br/>
            
             
               {/* <div className=" container-fluid full-ncontact">
              <div className="container contactus-page">
                 <Fade down>
                 <div className="row contactus-row">
                     <div className="col-lg-2"></div>
                     
                     <div className="col-lg-4">
                     <div className="contactnew-card">
                     <span className="contact-icon"><i class="fa-solid fa-location-dot"></i></span>
                         <p className="icon-firsttxt">Innovation lab</p>
                         <p className="icon-subtxt">No.30/6 Karthik Tower,2nd Floor,Church 7th Street,T.T. Nagar
                        Karaikudi, Tamilnadu 630001</p>
                         </div>
                     </div>
                     <div className="col-lg-4">
                     <div className="contactnew-card">
                     <span className="contact-icon"><i class="fa-solid fa-location-dot"></i></span>
                         <p className="icon-firsttxt">USA</p>
                         <p className="icon-subtxt">1301 Palmer Drive, West Lafayette, IN 47906</p>
                         </div>
                     </div>
                     <div className="col-lg-2"></div>
                 </div></Fade>
                 
             </div> 
    <div className="maincontact-ncontent"> 
      <Fade up>
          <div className="row contact-row">

          <div className="col-lg-2"></div>
          
          <div className="col-lg-4 cntctus">
            
           <div className="inners-imgtxt">
               <div className="arange-txt">
               <span className="innertxt-head">Contact Us</span><br/><br/>
               <p className="innertxt-content"><i class="fas fa-phone"></i>&nbsp;&nbsp;Phone : +91-8026581990</p><br/>
               <p className="innertxt-last"><i class="fas fa-envelope"></i>&nbsp;&nbsp;Email : contact@kdptec.com</p>
               </div><br/>
               <div className="inner-iconsbox">
                   <p className="inner-contacticon">
                     
                   <span className="instacon"><i class="fa-brands fa-instagram"></i></span>&nbsp;&nbsp;
                   <span className="fbcon"><i class="fa-brands fa-facebook-square"></i></span> &nbsp;&nbsp;
                   <span className="linkcon"><i class="fa-brands fa-linkedin-in"></i></span></p>
               </div>
           </div>
          </div>
          
          <div className="col-lg-4 ">
            <div className="innercon-form">
                <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                    <input type="text" name="Name" id="names"class="form-control formchanges" placeholder="Name" onChange={e=>setName(e.target.value)}/>
                    {error&&name.length<=0?
                   <p style={{padding:"5px",color:"red"}}>Name can't be empty</p>:''}
                    </div>
                    <div className="col-lg-1"></div>
                </div><br/>
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                    <input type="email" name="Email" id="email" class="form-control formchanges" placeholder="Email"  onChange={e=>setEmail(e.target.value)}/>
                    {error&&email.length<=0?
                    <p style={{padding:"5px",color:"red"}}>Email is required</p>:''}
                    </div>
                    <div className="col-lg-1"></div>
                </div>
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 butnsrow">
                    <textarea className="form-botomtext" name="Message" id="mesg" placeholder="Message" onChange={e=>setMessage(e.target.value)}></textarea>
                    {error&&message.length<=0?
                    <p style={{padding:"5px",color:"red"}}>Message can't be empty</p>:''}
                    <br/>
                    <div className="change-btn">
                    <button type="Submit" value="Submit" className="cntct-btns">Submit</button>
                    </div>
                    </div>
                    <div className="col-lg-1"></div>
                </div>
                 </form>
            </div>
          </div>
         
          <div className="col-lg-2"></div>
    
      </div>
      </Fade>
   </div> 
   </div> <br/><br/> */}
  
  <Arrow/>
<Footer/>
        </div>
    )
}
export default Contactus;