import React,{useState,useEffect} from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import '../Additionalcss/jobs.css'
import seo from '../image/seo.jpg'
import graphic from '../image/graphic.jpg'
import app from '../image/app.jpeg'
import bi from '../image/bi.jpg'
import content from '../image/content.jpg'
 function Jobs() {
//        const[resource,setResource]=useState("posts")
//        console.log("up-render");
//        useEffect(() => {
//            console.log("render")
//            fetch('https://jsonplaceholder.typicode.com/todos/1')
//   .then(response => response.json())
//   .then(json => console.log(json))
          
//        })

    
    return (
        <div>
            <Header/>
            <div className="container">
                <div className="job-top">
                    <span className="job-tophead">Our Jobs</span>
                </div><br/><br/>
                <div className="row">
                    <div className="col-lg-6">
                     <p className="job-position">SEO Executive</p>
                     <p className="job-exp">Req: 1-2 Years Experience</p>
                     <p className="job-contents">Must have at least 1 year experience working with on-page/off-page optimization,link-building,directory submissions
                     blog/forum commenting, article submissions,back link strategy Implementation and link wheeling
                     </p>
                    </div>
                    <div className="col-lg-6">
                        <img src={seo} className="job-img"/>
                    </div>
                </div><br/>
                <div className="row">
                <div className="col-lg-6">
                    <img src={content} className="job-img"/>
                    </div>
                    <div className="col-lg-6">
                     <p className="job-position">Content Writer</p>
                     <p className="job-exp">Req: 0-1 Years Experience</p>
                     <p className="job-contents">Writing creative product descriptions, app notifications,emailers,SMS,Involving in writing,
                     blogging & SMO. Able to write creative and crisp content.
                     </p>
                    </div>
                    
                </div><br/>
                <div className="row">
                    <div className="col-lg-6">
                     <p className="job-position">Graphic Designer</p>
                     <p className="job-exp">Req: 0-1 Years Experience</p>
                     <p className="job-contents">As a hands-on graphic designer, will bring a mix of creativity, marketing
                     expertise and business understanding to the position.
                     </p>
                    </div>
                    <div className="col-lg-6">
                    <img src={graphic} className="job-img"/>
                    </div>
                </div><br/>
                <div className="row">
                <div className="col-lg-6">
                    <img src={app} className="job-img"/>
                    </div>
                    <div className="col-lg-6">
                     <p className="job-position">Mobile App Developer</p>
                     <p className="job-exp">Req: 0-1 Years Experience</p>
                     <p className="job-contents">Basic Knowledge in HTML, MVC, Angular, JavaScript. Ready to Learn new technologies
                     </p>
                    </div>
                    
                </div><br/>
                <div className="row">
                {/* <div className="col-lg-6">
                    <img src={bi} className="job-img"/>
                    </div> */}
                    <div className="col-lg-6">
                     <p className="job-position">Business Intelligence</p>
                     <p className="job-exp">Req: 0-1 Years Experience</p>
                     <p className="job-contents">The candidates knows business intelligence tools, can model multi dimensional datasets, and 
                     can partner effectively with business leaders
                     </p>
                    </div>
                    <div className="col-lg-6">
                    <img src={bi} className="job-img"/>
                    </div>
                    
                </div>
                
            </div><br/>

             {/* <button type="button" onClick={()=>setResource('posts')}>post</button>
             <button type="button" onClick={()=>setResource('users')}>read</button>
             <button type="button" onClick={()=>setResource('comments')}>comments</button>
    

             <h1>{resource}</h1>
     */}

         <Footer/>
        </div>
    )
}
export default Jobs;