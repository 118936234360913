import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import seo from '../image/seo.jpg'
import graphic from '../image/graphic.jpg'
import app from '../image/app.jpeg'
import bi from '../image/bi.jpg'
import content from '../image/content.jpg'
 function Careers() {
    return (
        <div>
            <Header/>
            <div className="container career-con">
                {/* <div className="job-top">
                    <span className="job-tophead">Our Jobs</span>
                </div> */}
                <div className="firstabout" id="careertop"></div>
                {/* <br/><br/><br/><br/><br/> */}
                <div className="about-head">Careers</div>
                <div className="row careerrw">
                <div className="col-lg-6">
                     <p className="job-position">Mobile App Developer</p>
                     <p className="job-exp">Req: 0-1 Years Experience</p>
                     <p className="job-contents">Basic Knowledge in HTML, MVC, Angular, JavaScript. Ready to Learn new technologies
                     </p>
                    </div>
                    <div className="col-lg-6">
                        <img src={app} className="job-img"  title="Mobile App Developer" alt="Mobile App Developer"/>
                    </div>
                </div><br/>
                <div className="row crrsec">
                <div className="col-lg-6">
                    <img src={content} className="job-img" title="Content Writer" alt="Content Writer"/>
                    </div>
                    <div className="col-lg-6">
                     <p className="job-position">Content Writer</p>
                     <p className="job-exp">Req: 0-1 Years Experience</p>
                     <p className="job-contents">Writing creative product descriptions, app notifications,emailers,SMS,Involving in writing,
                     blogging & SMO. Able to write creative and crisp content.
                     </p>
                    </div>
                    
                </div><br/>
                <div className="row crrthrd">
                    <div className="col-lg-6">
                     <p className="job-position">Graphic Designer</p>
                     <p className="job-exp">Req: 0-1 Years Experience</p>
                     <p className="job-contents">As a hands-on graphic designer, will bring a mix of creativity, marketing
                     expertise and business understanding to the position.
                     </p>
                    </div>
                    <div className="col-lg-6">
                    <img src={graphic} className="job-img" title="Graphic Designer" alt="Graphic Designer"/>
                    </div>
                </div><br/>
                <div className="row crrfur">
                <div className="col-lg-6">
                    <img src={seo} className="job-img" title="SEO Executive" alt="SEO Executive"/>
                    </div>
                   
                    
                    <div className="col-lg-6">
                     <p className="job-position">SEO Executive</p>
                     <p className="job-exp">Req: 1-2 Years Experience</p>
                     <p className="job-contents">Must have at least 1 year experience working with on-page/off-page optimization,link-building,directory submissions
                     blog/forum commenting, article submissions,back link strategy Implementation and link wheeling
                     </p>
                    </div>
                </div><br/>
                <div className="row crrfv">
                {/* <div className="col-lg-6">
                    <img src={bi} className="job-img"/>
                    </div> */}
                    <div className="col-lg-6">
                     <p className="job-position">Business Intelligence</p>
                     <p className="job-exp">Req: 0-1 Years Experience</p>
                     <p className="job-contents">The candidates knows business intelligence tools, can model multi dimensional datasets, and 
                     can partner effectively with business leaders
                     </p>
                    </div>
                    <div className="col-lg-6">
                    <img src={bi} className="job-img" title="Business Intelligence" alt="Business Intelligence"/>
                    </div>
                    
                </div>
                
            </div>
            <p className="resume">Please send your resumes to careers@kdptec.com or resumes@kdptec.com</p>
            <Footer/>
        </div>
    )
}
export default  Careers;