import React,{ useEffect,useState }  from 'react'
import { NavHashLink} from 'react-router-hash-link';
import { NavLink, Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Contactpage from '../Components/Contactpage';
import '../Additionalcss/Home.css'
import Header from '../Components/Header'
import Arrow from '../Components/Arrow'
import {Fade,Zoom,Flip} from 'react-reveal';
import Footer from '../Components/Footer'
import { Carousel } from 'react-bootstrap'
// import Slider from "react-slick";
import auto from '../image/webDevo.png'
import application from '../image/Appservice.jpg'
import datain from '../image/DataIn.png'
import imgone from '../image/imgone.jpg'
import imgtwo from '../image/imgtwo.jpg'
import sushma from '../image/sushma.jfif'
import imgfour from '../image/imgfour.png'
import digital from '../image/digital.png'
import cloudE from '../image/cloudE.png'
import DmicraterE from '../image/DmicraterE.png'
import paro from '../image/paro.png'
import TC3 from '../image/TC3.png'
import logo2 from '../image/logo2.png'
import infone from '../image/mobile1.png'
import jop from '../image/jop.jpg'
import infothree from '../image/infotwo.png'
import ites2 from '../image/ites12.png'
import infofive from '../image/quality3.png'
import tech from '../image/tech.jpg'
import lifeone from '../image/lifeone.png'
import lifetwo from '../image/lifetwo.png'
import lifethree from '../image/lifethree.png'
import lifefour from '../image/lifefour.png'
import mission from '../image/mission.jpg'
import vission from '../image/vission.jpg'
import goal from '../image/goal.png'
import value from '../image/value.jpg'
import online from '../image/online.jpg'
import lap from '../image/lap.jpg'
import quality3 from '../image/quality3.jpg'
import sys from '../image/sys.jpg'

// import two from '../image/two.jpg'
 function Home() {
     const[name,setName]=useState("")
     const[email,setEmail]=useState("")
     const[message,setMessage]=useState("")
     const[error,setError]=useState(false)
     const[com,setCom]=useState(false)
     const[expe,setExpe]=useState(false)
     const[pro,setPro]=useState(false)
     const[talks,setTalks]=useState(false)
  async function handleSubmit(event){
    event.preventDefault();
     if(name.length==0){
       setError(true)
     }
     if (email.length==0){
       setError(true)
     }
     if (message.length==0){
      setError(true)
    }
      var Name=document.getElementById("names")
      var Email=document.getElementById("email")
      //localStorage.setItem("names", Name);
    //   localStorage.setItem('user', JSON.stringify(result.token));
     
    //   var Message=document.getElementById("mesg").value
      
    
      const data=new FormData(event.target);
       console.log("submit1");
          const url="https://apitest.kdptec.com/api/Contactpage/post";
        // const url="https://localhost:44315/api/Contactpage/post";
      
        let user =(localStorage.getItem('user'));
    const accessToken=user;
        const option={
          method:"POST",
          headers:{
            //"Content-Type: "application/json",
              // "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
          },
           body:data
      };
      const response = await fetch(url,option);
      const result = await response.json();
      if (result.status === "Success" ){
        alert(
            result.message);
    //        let user = JSON.parse(localStorage.getItem('user'));
    //     //const accessToken=user;
    //         // navigate('/login');
        }
        else{
       alert(
            result.message);
        }
      
  }


  // const [openmission, setOpenmission] = useState(false)
  // const [openvision, setOpenvision] = useState(false)
  // const [opengoal, setOpengoal] = useState(false)
  // const [openvalue, setOpenvalue] = useState(false)

    return (
            <div>
    
            
            <Header/>

            
            <div className=" container-fluid carousel-container ">
              <div className="row frontcomrow">
                <div className="col-lg-6 topcontents">
                 <div className="contents">
                   <span className="landingtxt">Affordable  Solutions </span><br/>
                   <span className="landingtxts">That</span>
                   <br/>
                   <span className="landingtxt">Make A Difference</span><br/>
                   {/* <span className="landingtxt">Your Life Easy</span> */}
                 </div>
                </div>
                <div className="col-lg-6">
                 <img src={lap} className="topimg"/>
                </div>
            
              </div>
          
  </div> 
  
 

   <div className="container-fluid hoverup-text"id="serv-sec" style={{background:"linear-gradient(280deg, rgb(89, 92, 255),rgb(128 239 255))"}}>
    <div className="row">
    <div className="col-lg-2"></div> 
    <div className="col-lg-8 textcol">
     <p className="hovertext">Expertise</p><br/>
  
    </div>
    <div className="col-lg-2"></div>
    </div> 
   
  
     <div className=" fullhover">
  
  <div className="row onestrow">  
  <div className="col-lg-4 hoverbox">
  <div className="hovercon"> 
  <img src={infone} alt="Mobile Application Development " title="Mobile Application Development" className="hoverimg"/>
 
  
  <div className="overlay">
  <div className="uptxt-head">  
  <HashLink className="ul-link" to="/Services#mobile"><h1 className="styletext">Mobile Application Development</h1></HashLink></div>
   <div className="uptxt"> <p className="style-content1">Our high-quality light weight solutions are created to work across different platforms delivering the next generation digital user experience that will bring your business to the next level.

</p></div>
  </div>
  </div> 
  </div>

  <div className="col-lg-4 hoverbox">
  <div className="hovercon"> 
  <img src={infothree} alt="Web Application and Website Development" title="Web Application and Website Development " className="hoverimg"/>
 
  <div className="overlay">
  <HashLink className="ul-link" to="/Services#webapp"><h1 className="styletexts">Web Application and Website Development</h1></HashLink>
    <p className="style-content2">Our solutions have laser level focus on your needs using responsive, attractive and elegant design with a faster loading time that work seamlessly across different browsers and engage users at an in-depth level with their critical focus on user interface and security, and ingenuity in design while being easy to navigate. 

</p>
  </div>
  </div>
  </div>
  
 
  <div className="col-lg-4 hoverbox">
  <div className="hovercon"> 
  
  <img src={cloudE} alt="Cloud Computing" title="Cloud Computing" className="hoverimg"/>
  
  
  <div className="overlay">
  <HashLink className="ul-link" to="/Services#dataint"><h1 className="styletext">Cloud Computing</h1></HashLink>
    <p className="style-content3">
    Our guidance will allow you to choose appropriate cost-effective cloud services that allow you to innovate faster, be flexible with your resources, and achieve economies of scale.






    </p>
  </div>
  </div>
  </div>
  </div><br/> 
  <div className="row secstrow">
  <div className="col-lg-4 hoverbox">
  <div className="hovercon"> 
  
  <img src={DmicraterE} alt="Data Migration" title="Data Migration" className="hoverimg"/>
  
  
  <div className="overlay">
  <HashLink className="ul-link" to="/Services#dataint"><h1 className="styletext">Data Migration</h1></HashLink>
  <p className="style-content3">
  Our creative solutions help you lower your operating costs, run your infrastructure efficiently, and allow scalability as your business needs change.




    </p>
  </div>
  </div>
  </div>
  <div className="col-lg-4 hoverbox">
  <div className="hovercon"> 
  
  <img src={datain} alt="Business Intelligence & Analytics" title="Business Intelligence & Analytics" className="hoverimg"/>
  
  
  <div className="overlay">
  <HashLink className="ul-link" to="/Services#dataint"><h1 className="styletext">Business Intelligence & Analytics</h1></HashLink>
  <p className="style-content3">Our solutions tailor made to your needs will deliver deep insight allowing you to make better decisions and drive cost down, improve performance and expand your business.
  </p>
  </div>
  </div>
  </div>
  <div className="col-lg-4 hoverbox">
  <div className="hovercon"> 
  <img src={digital} alt="Digital Marketing" title="Digital Marketing" className="hoverimg"/>
 
  <div className="overlay">
  <HashLink className="ul-link" to="/Services#digit"> <h1 className="styletext">Digital Marketing</h1></HashLink>
    <p className="style-content4">Our expertise by utilizing the latest web tools in the domain of SEO, Content Management and Social Media Marketing will help expand your company’s digital reach and grow your business.

</p>
  </div>
  </div>
  </div>
  </div><br/>
  <div className="row secstrow"> 
  <div className="col-lg-4 hoverbox">
  <div className="hovercon"> 
  <img src={infofive} alt="Quality Assurance" title="Quality Assurance" className="hoverimg"/>
 
  
  <div className="overlay">
  <HashLink className="ul-link" to="/Services#quality"> <h1 className="styletext">Quality Assurance</h1></HashLink>
    <p className="style-content5">We offer high quality assurance and software testing solutions using the latest technologies to enhance the quality of the products that are built to fit in within your existing environment with minimal disruption encompassing speed and sanctity of security.

</p>
  </div>
  </div> 
  </div>
  
  
 
  
  <div className="col-lg-4 hoverbox">
  <div className="hovercon"> 
  
  <img src={application} alt="Application Services and Modernization" title="Application Services and Modernization" className="hoverimg"/>
  
  
  <div className="overlay">
  <HashLink className="ul-link" to="/Services#appser"> <h1 className="styletext">Application Services and Modernization</h1></HashLink>
    <p className="style-content6">Our expertise in customized software development solutions will be handy in the process of creating new applications or migrate your outdated web and mobile applications to latest technologies.

</p>
  </div>
  </div>  
  </div>
  
  <div className="col-lg-4 hoverbox">
  <div className="hovercon"> 
  <img src={ites2} alt="IT Enabled Services" title="IT Enabled Services" className="hoverimg"/>
 
  
  <div className="overlay">

  <HashLink className="ul-link" to="/Services#ites"> <h1 className="styletext">IT Enabled Services</h1></HashLink>
    <p className="style-content7">We provide expert advice across a range of platforms and technologies in all key areas of the IT infrastructure from desktop to data center.

</p>
  </div>
  </div>
  </div></div><br/>
  <div className="row secstrow">
    <div className="col-lg-4"></div>
 
  <div className="col-lg-4 hoverbox">
  <div className="hovercon"> 
  <img src={tech} alt="Staff Augmentation" title="Staff Augmentation" className="hoverimg"/>
 
  
  <div className="overlay">
  <HashLink className="ul-link" to="/Services#tech"> <h1 className="styletext">Staff Augmentation</h1></HashLink>
    <p className="style-content8">We can provide staff with latest skills across a range of experience levels that can become a part your team and work under your leadership.

</p>
  </div>
  </div>
  </div>
  <div className="col-lg-4"></div>
  </div>
  
  
  
  
  
  
  
  </div>   
  </div> 
  

   <div className="container-fluid cardcon-text "id="team">
    <div className="row">
      <div className="col-lg-2"></div>
      <div className="col-lg-8 cardcol">
<div className="cardcol-text">Leaders</div>

      </div>
      <div className="col-lg-2"></div>
    </div>
   
         <div class="container card-container topcontainer">   
           <div className="row" 
           style={{marginTop:"13px"}}
           >
               {/* <div className="col-lg-12"></div> */}
               {/* <div className="col-lg-1"></div> */}
               <div className="col-lg-4 card-cn">
  <div class="card card-3">
    <div class="card-img">
    <img src={imgtwo} title="Sudha" alt="Sudha" className="tchimg"/>
    </div>
    <a href="" class="card-link">
      <div class="card-img-hovered"></div>
    </a>
    <div class="card-info">
      <div class="card-about">
     
      </div>
      <h1 class="card-title">Sudha Harish</h1>
      
    </div>
  </div></div>
 
               <div className="col-lg-4 card-cn">
  <div class="card card-2">
    <div class="card-img">
        <img src={sushma} title="Sushma" alt="Sushma" className="tchimg"/>
    </div>
    <a href="" class="card-link">
      <div class="card-img-hovered"></div>
    </a>
    <div class="card-info">
      <div class="card-about">
     
      </div>
      <h1 class="card-title">Sushma Thammaiah</h1>
      
    </div>
  </div></div>
  
               <div className="col-lg-4 card-cn">
  <div class="card card-4">
    <div class="card-img">
        <img src={imgfour} title="Vasanth" alt="Vasanth" className="tchimg"/>
    </div>
    <a href="" class="card-link">
      <div class="card-img-hovered"></div>
    </a>
    <div class="card-info">
      <div class="card-about">
     
      </div>
      <h1 class="card-title">Vasantha Kumar</h1>
   
    </div>
  </div></div>
   {/* <div className="col-lg-1"></div> */}
  </div>

  </div>
             
                  
              
  </div> 
   <div className="container-fluid cardcon-text "id="team">
    <div className="row">
      <div className="col-lg-2"></div>
      <div className="col-lg-8 cardcol">
<p className="cardcolf-text">Founder</p>
      </div>
      <div className="col-lg-2"></div>
    </div>

   
             <div class="container card-container">   
           <div className="row" 
           style={{marginTop:"-5px"}}
           >
             
               <div className="col-lg-4">
                 
               </div>
              
  <div className="col-lg-4">
<div className="Fcard-cn">
           <div class="card card-1">
    <div class="card-img">
        <img src={imgone} title="Anita-Rao" alt="Anita-Rao" className="tchimg"/>
    </div>
    <a href="" class="card-link">
      <div class="card-img-hovered"></div>
    </a>
    <div class="card-info">
      <div class="card-about">
  
      </div>
      <h1 class="card-title">Anita Rao</h1>
     
    </div>
  </div>
  </div>
  </div>
 
 
   <div className="col-lg-4"></div>
  </div>

  </div>
             
              
  </div> <br/>
  
<div className="container-fluid bottom-container" id="product">
  
  <div className="row bottom-content">

    <div className="container">
    <p className="project-head">OUR PRODUCTS</p>
<div className="row probox-row">
    <div className="col-lg-4">
     <div className="pro-box1">
    <div className="paro">
    <p className="pro-name"><img src={paro} title="paropakari" alt="paropakari" className="pro-icon"/>&nbsp;&nbsp;<a href="https://paropakari.com/#/"target="_blank" rel="nofollow"><span className="link1">Paropakari</span></a></p>
    </div>
     </div>
    </div>
    <div className="col-lg-4">
 <div className="pro-box2">
       <div className="found">
       <p className="pro-name"><img src={logo2} title="KDP_Foundation" alt="KDP_Foundation" className="pro-icon"/>&nbsp;&nbsp;<a href="https://kdpfoundation.org/"target="_blank" rel="nofollow"><span className="link2">Foundation</span></a></p>
       </div>
     </div>
    </div>
    <div className="col-lg-4">
    <div className="pro-box3">
       <div className="cool">
       <p className="pro-name"><img src={TC3} title="Thatscool" alt="Thatscool" className="pro-icon"/>&nbsp;&nbsp;<a href="https://thatscool.in/#/LandingPage"target="_blank" rel="nofollow"><span className="link3">Thatscool</span></a></p>
       </div>
       </div>
    </div>
</div>


    </div>
    {/* <div className="col-lg-8 pro-link"> */}
      
    {/* <div className="container project">
             <p className="project-head">OUR PRODUCTS</p>
          <p className="pro-name"><img src={paro} className="pro-icon"/>&nbsp;&nbsp;<a href="https://paropakari.com/#/"target="_blank"><span className="link1">Paropakari</span></a></p>
             <p className="pro-name"><img src={logo2} className="pro-icon"/>&nbsp;&nbsp;<a href="https://kdpfoundation.org/"target="_blank"><span className="link2">Foundation</span></a></p>
            <p className="pro-name"><img src={TC3} className="pro-icon"/>&nbsp;&nbsp;<a href="https://thatscool.in/#/LandingPage"target="_blank"><span className="link3">Thatscool</span></a></p>
           </div> */}
          
    {/* </div> */}
    {/* <div className="col-lg-2"></div> */}
  </div>

</div>

<div className="container-fluid contact-section">
  <Contactpage/>
  
</div>
{/* <div className="all-foot"> */}
<div className="container-fluid fullfooter">
<div className="footersection">
  <div className="row foot-row">
    <div className="col-lg-1"></div>
    <div className="col-lg-2 com-row">
     <span className="foothead">Company</span>
     {/* <span className="foot-sympols"
     onClick={() =>setCom(!com)}
     >&nbsp;&nbsp;&nbsp;
      {com===true?<i class="fa-solid fa-minus"></i> : <i class="fa-solid fa-plus" /> }
    </span> */}




     
     {/* {com===true?( */}
     <div className="hide-content" >
     <NavHashLink  to="/About#top"><p className="footer-menu">About</p></NavHashLink>
     <NavHashLink  to="/#team"> <p className="footer-menu">Team</p></NavHashLink>
     <NavHashLink  to="/Lifehere#lifetop"><p className="footer-menu">Life Here</p></NavHashLink>
     <NavHashLink  to="/Givingback#givetop"><p className="footer-menu">Giving Back</p></NavHashLink>
     <NavHashLink  to="/Careers#careertop"><p className="footer-menu">Careers</p></NavHashLink>
     </div>
    {/* //  ):null} */}
     {/* <NavHashLink  to="/Contactus"><p className="footer-menu">Contact Us</p></NavHashLink> */}
    </div>
    <div className="col-lg-3 exp-row">
    <span className="foothead">Expertise</span>
    {/* <span className="foot-sympols"
     onClick={() =>setExpe(!expe)}
     >&nbsp;&nbsp;&nbsp;
     
     {expe===true?<i class="fa-solid fa-minus"></i> : <i class="fa-solid fa-plus" /> }
     
     
     </span> */}
    
    {/* {expe===true?( */}
      <div className="hide-content">
    <NavHashLink  to="/Services#mobile"><p className="footer-menu">Mobile Application Development</p></NavHashLink>
    <NavHashLink  to="/Services#webapp"><p className="footer-menu">Web Application and Website Development</p></NavHashLink>
    <NavHashLink  to="/Services#cloudid"><p className="footer-menu">Cloud Computing</p></NavHashLink>

<NavHashLink  to="/Services#datamig"><p className="footer-menu"> Data Migration</p> </NavHashLink>
    <NavHashLink  to="/Services#dataint"><p className="footer-menu">Business Intelligence & Analytics</p></NavHashLink>
    <NavHashLink  to="/Services#digit"><p className="footer-menu">Digital Marketing</p></NavHashLink>
    <NavHashLink  to="/Services#quality"><p className="footer-menu">Quality Assurance</p></NavHashLink>
    <NavHashLink  to="/Services#appser"><p className="footer-menu">Application Services & Modernization</p></NavHashLink>
    <NavHashLink  to="/Services#ites"><p className="footer-menu">IT Enabled Services</p></NavHashLink>
    <NavHashLink  to="/Services#tech"><p className="footer-menu">Staff Augmentation</p></NavHashLink></div>
    {/* ):null} */}
    </div>
  
    <div className="col-lg-2 pro-col">
    <NavHashLink to="/#product"><span className="foothead">Products</span></NavHashLink>
    {/* <span className="foot-sympols"
     onClick={() =>setPro(!pro)}
     >&nbsp;&nbsp;&nbsp;
         {pro===true?<i class="fa-solid fa-minus"></i> : <i class="fa-solid fa-plus" /> }
      
     
     </span> */}
    
    {/* {pro===true?( */}
      <div className="hide-content">
    <p className="footer-menu"><a href="https://paropakari.com/#/"target="_blank"><span className="flink  link1">Paropakari</span></a></p>
    <p className="footer-menu"><a href="https://kdpfoundation.org/"target="_blank"><span className="flink link2">KDP Foundation</span></a></p>
    <p className="footer-menu"><a href="https://thatscool.in/#/LandingPage"target="_blank"><span className="flink link3">Thatscool</span></a></p>
    </div>
    {/* ):null} */}
    </div>
    <div className="col-lg-4 tlk-row">
    <NavHashLink to="/Talktous"><span className="footheads">Talk to Us</span></NavHashLink>
    {/* <span className="foot-sympols"
     onClick={() =>setTalks(!talks)}
     >&nbsp;&nbsp;&nbsp;
     
     {talks===true?<i class="fa-solid fa-minus"></i> : <i class="fa-solid fa-plus" /> }     
     
     </span> */}
    <br/>
    {/* {talks===true?( */}
    <div className="innercon-form">
      <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                    <input type="text" name="Name" id="hometalk"  class="form-control" placeholder="Name" onChange={e=>setName(e.target.value)}/>
                   {error&&name.length<=0?
                   <p style={{padding:"5px",color:"red"}}>Name can't be empty</p>:''}
                    </div>
                    <div className="col-lg-1"></div>
                </div><br/>
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                    <input type="email" name="Email" id="hometalk" class="form-control " placeholder="Email" onChange={e=>setEmail(e.target.value)}/>
                    {error&&email.length<=0?
                    <p style={{padding:"5px",color:"red"}}>Email is required</p>:''}
                    </div>
                    <div className="col-lg-1"></div>
                </div>
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                    <textarea className="form-botomtexts" name="Message" placeholder="Message"
                    onChange={e=>setMessage(e.target.value)}
                    ></textarea>
                    {error&&message.length<=0?
                    <p style={{padding:"5px",color:"red"}}>Message can't be empty</p>:''}
                    <br/>
                    <div className="change-btns">
                    <button type="Submit" value="Submit" className="cntct-btn btn1">Submit</button>
                    </div>
                    </div>
                    <div className="col-lg-1"></div>
                </div>
                </form>

            </div>
            {/* ):null} */}
    </div>
  </div>
</div>
</div>
{/* </div> */}

<Arrow/>
{/* <br/><br/> */}
   <Footer/>
          </div>   
    )
}
export default Home;