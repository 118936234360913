
import './App.css';
import Home from './Screen/Home';
import {
  HashRouter,
  Route,
  Routes,
} from "react-router-dom";

import Services from './Screen/Services';
import Contactus from './Screen/Contactus';
import Contactpage from './Components/Contactpage';
import About from './Screen/About';
import Talktous from './Screen/Talktous';
import Lifehere from './Screen/Lifehere';
import Givingback from './Screen/Givingback';
import Careers from './Screen/Careers';
import Jobs from './Screen/Jobs'
import Technologies from './Screen/Technologies';
 


function App() {
  return (
    <HashRouter>
    <Routes>
    <Route exact path="/" element={<Home />} />
    <Route path="/About" element={<About/>}/>
    <Route path="/Givingback" element={<Givingback/>}/>
    <Route path="/Lifehere" element={<Lifehere/>}/>
    <Route path="/Services" element={<Services/>} />
    <Route path="/Careers" element={<Careers/>}/>
    <Route path="/Contactus" element={<Contactus/>} />
    <Route path="/Technologies" element={<Technologies/>} />
    <Route path="/Talktous" element={<Talktous/>} />
    <Route path="/Contactpage" element={<Contactpage />} />
    <Route path="/Jobs" element={<Jobs/>} /> 
 
  
    
    </Routes>
  </HashRouter>
  );
}

export default App;
