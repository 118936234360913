import React,{ useEffect,useState }  from 'react'
import {Fade,Zoom,Flip} from 'react-reveal';
import goal from '../image/goal.png'
import value from '../image/value.jpg'
import Header from '../Components/Header'
import mission from '../image/mission.jpg'
import vission from '../image/vission.jpg'
import Footer from '../Components/Footer'
 function About() {

    const [openmission, setOpenmission] = useState(false)
    const [openvision, setOpenvision] = useState(false)
    const [opengoal, setOpengoal] = useState(false)
    const [openvalue, setOpenvalue] = useState(false)
    return (
        <div>
            <Header/>
            <div className="firstabout" id="top"></div>
            <div className="container-fluid fullabout">
  <div className="container-fluid new-container-fluid " id="about">

  <div className="about-head">About</div>
    <div className="row info-fullbox">
     <div className="col-lg-2"></div> 
    <div className="col-lg-8 info-box">
    <span className="info-text">
    
    <p className="aboutus-txt">We are a woman founded tech company, led by a majority of women that is working towards making a small contribution to challenge the gender imbalance that exists in the tech industry

     <br/><br/>We believe that women and rural and small-town talent given an opportunity can create wonders!!! We are bringing opportunities to these people to pursue a career that otherwise wouldn’t exist 
<br/><br/>
     {/* We serve the Construction and Real Estate, Information Technology, and Accounting and Financial Industries in India and the Global Market */}
     Our pool of enthusiastic tech savvy talent trained in the most advanced technologies can handle any challenge thrown at them 

     </p>
   
    </span>
    </div>
      <div className="col-lg-2"></div> 
    </div></div>
 <div className="row">
   <div className="col-lg-2"></div>
   <div className="col-lg-10">
      <div className="col-lg-6">
    <p className="aboutmis-content1"  style={{textAlign:"left",margin:"-4px -8px",fontSize:"20px"}}  >MISSION</p>
    </div>
   </div>
 </div>
<div className="row info-fullbox">
  <div className="col-lg-2"></div>
  <div className="col-lg-1">
  <img src={mission} className="mis-icon"style={{marginTop:"20px"}}/>
  </div>
  <div className="col-lg-6">
   
    <p className="aboutmis-head" style={{marginTop:"40px",fontSize:"16px"}}> To develop affordable solutions that make a difference</p>
  </div>

<div className="col-lg-2"></div>
</div>

<br/>
<div className="row">
   <div className="col-lg-2"></div>
   <div className="col-lg-10">
     <div className="col-lg-6">
    <p className="aboutmis-content1" style={{textAlign:"left",margin:"-4px -8px",fontSize:"20px"}}  >VISION</p>
    </div>
   </div>
 </div>
<div className="row info-fullbox">
  <div className="col-lg-2"></div>
  <div className="col-lg-1">
  <img src={vission} className="mis-icon"style={{marginTop:"20px"}}/>
  </div>
  <div className="col-lg-6">
    
    <p className="aboutmis-head" style={{marginTop:"35px",fontSize:"16px"}}> To be the partner of choice by delivering the best customer experience by proactively anticipating, listening and responding to customer's needs</p>
  </div>

<div className="col-lg-2"></div>
</div>

<br/>
<div className="row">
   <div className="col-lg-2"></div>
   <div className="col-lg-10">
     
   </div>
 </div>
<div className="row info-fullbox">
  <div className="col-lg-2"></div>
  <div className="col-lg-1 goalclg">
    <br/><br/>
    <p className="aboutmis-head"style={{textAlign:"left",margin:"21px 10px",fontSize:"20px",paddingBottom:"10px"}} >GOAL</p>

  <img src={goal} className="mis-icon" />
  </div>
  <div className="col-lg-6 goal-content">
    
    {/* <p className="aboutmis-head" style={{marginTop:"16px",fontSize:"16px"}}> 
    To be the partner of choice by delivering the best customer experience by proactively anticipating, listening and responding to customer's needs</p> */}
    <ul className="aboutmis-head" style={{marginTop:"75px",fontSize:"16px",marginLeft:"0",padding:"12px"}}> 
         <li> Embrace highly skilled talent pool</li>
        <li>  Reduce the total cost of doing business</li>
         <li> Create and implement standard operating procedures that automatically leverages the strengths of talented and cost effective labour pool</li>
         <li> Provide specific resources that are challenging and expensive to hire in other areas of the globe</li>
         <li> Develop an awesome, dedicated, enthusiastic and loyal team by creating an environment of respect, trust and cooperation</li>
          </ul>
  </div>

<div className="col-lg-2"></div>
</div>

<br/>
<div className="row valuerow">
   <div className="col-lg-2"></div>
   <div className="col-lg-10">
     <div className="col-lg-6">
    <p className="aboutmis-head" style={{textAlign:"left",marginLeft:"-15px",fontSize:"20px",marginTop:"27px"}} >OUR VALUES</p>
    </div>
   </div>
 </div>
<div className="row info-fullbox">
  <div className="col-lg-2"></div>
  <div className="col-lg-1">
  <img src={value} className="mis-icon" style={{marginTop:"5px"}}/>
  </div>
  <div className="col-lg-6">
    
   
    <ul className="aboutmis-head" style={{marginTop:"-2px",fontSize:"16px",paddingLeft:"7px"}}> 
        <li>  Fun</li>
         <li> Family</li>
         <li> Work Life Balance</li>
         <li> Make A Difference</li>
          </ul>
  </div>

<div className="col-lg-2"></div>
</div>


 
</div>
<Footer/>
        </div>
    )
}
export default About;