import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import lap from '../image/lap.jpg'
import Arrow from '../Components/Arrow'

import tecno1 from '../image/tecno1.png'
import tecno2 from '../image/tecno2.png'
import tecno3 from '../image/tecno3.png'
import tecno4 from '../image/tecno4.png'
import tecno5 from '../image/tecno5.png'
import tecno61 from '../image/tecno61.png'
import tecno7 from '../image/tecno7.png'
import tecno8 from '../image/tecno8.png'
import tecno9 from '../image/tecno9.png'
import tecno10 from '../image/tecno10.png'
import tecno12 from '../image/tecno12.png'
import tecno13 from '../image/tecno13.png'
import tecno14 from '../image/tecno14.png'
import mauir from '../image/mauir.png'
import azurer from '../image/azurer.png'
import awsr from '../image/awsr.png'
function Technologies() {
    return(
        <div>
           <Header/>    

           <div className="firstabout" id="techtop"></div>
           <div className="conteiner-fluid techpage">
           <div className="about-head">Technologies</div>
             <div className="row tecfirst">
                 <div className="col-lg-2">

                 </div>
                 <div className="col-lg-2">
                  <div className="tec-cards">
                      <div className="tecno-img">
                          <img src={tecno1}className="tecno-pic"/>
                        
                      </div>
                      <div className="tecno-name">
                         <span className="tecno-txt">C#</span> 
                      </div>
                  </div>
                 </div>
                  <div className="col-lg-2">
                  <div className="tec-cards">
                      <div className="tecno-img">
                          <img src={tecno2}className="tecno-pic"/>
                        
                      </div>
                      <div className="tecno-name">
                         <span className="tecno-txt">React Js</span> 
                      </div>
                  </div>
                  </div>
                 <div className="col-lg-2">
                 <div className="tec-cards">
                      <div className="tecno-img">
                          <img src={tecno3}className="tecno-pic"/>
                        
                      </div>
                      <div className="tecno-name">
                         <span className="tecno-txt">React Native</span> 
                      </div>
                  </div>
                 </div>
                  <div className="col-lg-2">
                  <div className="tec-cards">
                      <div className="tecno-img">
                          <img src={tecno4}className="tecno-pic"/>
                        
                      </div>
                      <div className="tecno-name">
                         <span className="tecno-txt">Angular Js</span> 
                      </div>
                  </div>
                  </div>
                  <div className="col-lg-2">

                  </div>
                 </div> <br/><br/> 


                 <div className="row tec-second">
                 <div className="col-lg-2">

</div>
<div className="col-lg-2">
 <div className="tec-cards">
     <div className="tecno-img">
         <img src={tecno5}className="tecno-pic"/>
       
     </div>
     <div className="tecno-name">
        <span className="tecno-txt">Javascript</span> 
     </div>
 </div>
</div>
 <div className="col-lg-2">
 <div className="tec-cards">
     <div className="tecno-img">
         <img src={tecno61}className="tecno-pic"/>
       
     </div>
     <div className="tecno-name">
        <span className="tecno-txt">Ajax</span> 
     </div>
 </div>
 </div>
<div className="col-lg-2">
<div className="tec-cards">
     <div className="tecno-img">
         <img src={tecno7}className="tecno-pic"/>
       
     </div>
     <div className="tecno-name">
        <span className="tecno-txt">Sqlite</span> 
     </div>
 </div>
</div>
 <div className="col-lg-2">
 <div className="tec-cards">
     <div className="tecno-img">
         <img src={tecno8}className="tecno-pic"/>
       
     </div>
     <div className="tecno-name">
        <span className="tecno-txt">MS Sql Server</span> 
     </div>
 </div>
 </div>
 <div className="col-lg-2">

 </div>
  </div>    
  <br/><br/>
  <div className="row tec-thrd">

      <div className="col-lg-2"></div>
      <div className="col-lg-2">
 <div className="tec-cards">
     <div className="tecno-img">
         <img src={tecno9}className="tecno-pic"/>
       
     </div>
     <div className="tecno-name">
        <span className="tecno-txt">Xamarin</span> 
     </div>
 </div>
</div>
<div className="col-lg-2">
 <div className="tec-cards">
     <div className="tecno-img">
         <img src={tecno10}className="tecno-pic"/>
       
     </div>
     <div className="tecno-name">
        <span className="tecno-txt">Firebase</span> 
     </div>
 </div>
</div>
<div className="col-lg-2">
 <div className="tec-cards">
     <div className="tecno-img">
         <img src={tecno12}className="tecno-pic"/>
       
     </div>
     <div className="tecno-name">
        <span className="tecno-txt">Windows Service</span> 
     </div>
 </div>
</div>
<div className="col-lg-2">
<div className="tec-cards">
     <div className="tecno-img">
         <img src={tecno13}className="tecno-pic"/>
       
     </div>
     <div className="tecno-name">
        <span className="tecno-txt">My Sql</span> 
     </div>
 </div>
</div>
<div className="col-lg-2"></div>
  </div><br/><br/>
  <div className="row tec-four">
      <div className="col-lg-2"></div>
      <div className="col-lg-2">
      <div className="tec-cards">
     <div className="tecno-img">
         <img src={tecno14}className="tecno-pic"/>
       
     </div>
     <div className="tecno-name">
        <span className="tecno-txt">Dot Net</span> 
     </div>
 </div>
      </div>
      <div className="col-lg-2">
      <div className="tec-cards">
     <div className="tecno-img">
         <img src={mauir}className="tecno-pic"/>
       
     </div>
     <div className="tecno-name">
        <span className="tecno-txt">Maui</span> 
     </div>
 </div>

      </div>
      <div className="col-lg-2">
  <div className="tec-cards">
     <div className="tecno-img">
         <img src={azurer}className="tecno-pic"/>
       
     </div>
     <div className="tecno-name">
        <span className="tecno-txt">Azure</span> 
     </div>
 </div>
  </div>
  <div className="col-lg-2">
  <div className="tec-cards">
     <div className="tecno-img">
         <img src={awsr}className="tecno-picaws"/>
       
     </div>
     <div className="tecno-name">
        <span className="tecno-txt">Aws</span> 
     </div>
 </div>
  </div>

  </div>
  
           </div>
          <br/><br/>
          <Arrow/>
           <Footer/>
        </div>
    )
}
export default Technologies;
